import NeighborhoodEntity from "../../../domain/entities/NeighborhoodEntity";
import ZoneDto from "./ZoneDto";

const fromJSON = (data: any): NeighborhoodEntity => {
    return {
        id: data.id,
        name: data.name,
        zone_id: data.zone_id,
        zone: data.zone ? ZoneDto.fromJSON(data.zone) : undefined,
    }
}

export default {
    fromJSON,
}